const localeWrapperFlagIconsForAppointments = (locale) => {
    switch (locale) {
        case 'de':
            return 'de'
        case 'en':
            return 'gb'
        case 'da':
            return 'dk'
        case 'fr':
            return 'fr'
        case 'ru':
            return 'ru'
        case 'el':
            return 'gr'
        case 'lb-LU':
            return 'lu'
        case 'no':
            return 'no'
        case 'sv':
            return 'se'
        case 'uk':
            return 'ua'
        case 'fa':
            return 'af'
        case 'ar':
            return 'sa'
        case 'tr':
            return 'tr'
        default:
            return null
    }
}
export default localeWrapperFlagIconsForAppointments