import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { FormattedMessage } from "react-intl";

const RemoteSubmitButton = ({ dispatch }) => {
  return (
    <button
      type="submit"
      className="btn btn-green btn-block"
      onClick={() => dispatch(submit('contact'))}
      style={{
        fontSize: '1.2em',
        height: '50px',
        color: 'white'
      }}>
      <FormattedMessage id={"dataforms.book_appointment"}/>
    </button>
  );
};

export default connect()(RemoteSubmitButton);
