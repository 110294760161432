import React from 'react';

const Field = props =>
  props.columns === 2 ? (
    <div className="row">
      <div
        className={`col-md-6 pull-${props.placement}`}
        style={{ width: '465px' }}>
        {props.children}
      </div>
    </div>
  ) : (
    <div
      className={`col-md-6 pull-${props.placement}`}
      style={{ marginLeft: '-15px' }}>
      {props.children}
    </div>
  );

export default Field;
