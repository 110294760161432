import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Form, Control, Errors } from 'react-redux-form';
import Field from '../Field';
import PrivacyPolicy from './PrivacyPolicyTouch';
import styles from "../../styles/dataforms.module.css";

const query = new URLSearchParams(window.location.search);
const invite_url_token = query.get('url_token') ? query.get('url_token') : '';
const not_publicy_listed_token = query.get('hash') ? query.get('hash') : ''

let data_fields = [
  {
    name: 'firstName',
    field_name: 'firstName',
    placeholder: 'Vorname, First name',
    type: 'firstName',
    placement: 'left',
    columns: 1,
    mandatory: true,
    web_appointments: true,
    validation_texts: {
      required: 'Bitte geben Sie Ihren Vornamen ein.',
      minLength: 'Bitte geben Sie mindestens zwei Zeichen an.',
      maxLength: 'zuviele Zeichen.'
    }
  },
  {
    name: 'name',
    field_name: 'name',
    placeholder: 'Name',
    type: 'name',
    placement: 'left',
    columns: 1,
    mandatory: true,
    web_appointments: true,
    validation_texts: {
      required: 'Bitte geben Sie Ihren Namen ein.',
      minLength: 'Bitte geben Sie mindestens zwei Zeichen an.',
      maxLength: 'zuviele Zeichen.'
    }
  }
];

class UserForm extends React.Component {
  handleSubmit = () => {
    this.props.bookReservations(
      this.props.site_info.id,
      this.props.appointment_times.map(time => ( { key: time.reservation_key }) ),
      [{id: this.props.selected_service_data.info_fields.filter(field => field.type === 'given_name')[0].id, value: this.props.user_data.user.firstName},
        {id: this.props.selected_service_data.info_fields.filter(field => field.type === 'family_name')[0].id, value: this.props.user_data.user.name}],
      this.props.user_data.user.email1,
      !this.props.user_data.user.email1.length,
      this.props.selected_language.lang,
      invite_url_token,
      null,
      null,
      not_publicy_listed_token,
      this.props.selected_service).then((res) => console.log(res))
  };
  showPolicy = () => {
    this.props.setShowPolicy();
  };
  showModalPopUp = () => {
    let popUpObj = window.open(this.props.customer_policy_url_translations[this.props.selected_language.lang],"ModalPopUp","width=400," + "height=300");
    popUpObj.focus();
  }

  render() {
    let root = document.documentElement
    root.style.setProperty('--selected-color', this.props.site_info.button_active)
    root.style.setProperty('--checkbox-arrow', this.props.site_info.button_text_active)
    root.style.setProperty('--selected-border-color', this.props.site_info.button_active)
    const required = val => val && val.length;
    const minLength = len => val => val.length >= len;
    const maxLength = len => val => val.length <= len;
    const validEmail = val =>
      val.length ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val) : true;
    const confirmEmail = val => user.email1.value === val;
    const { activeInput, intl } = this.props;
    const { user } = this.props.user_data.forms;
    return (
      <div className="row">
        <div className="col-md-12">
          <Form model="user_data.user" onSubmit={v => this.handleSubmit(v)}>
            {data_fields.map((user_input, i) => (
              <div className={styles['col-half']} key={i}>
                {user_input.name === 'email1' ? (
                  <p>
                    Die Eingabe einer Email Adresse ist Optional. Wenn Sie eine
                    Email Adresse eingeben erhalten Sie eine Erinnerung für
                    Ihren Termin.
                  </p>
                ) : null}
                <div>
                  <Control.text
                    model={`.${user_input.field_name}`}
                    type={user_input.type}
                    placeholder={user_input.placeholder}
                    className="form-control form_field"
                    id={user_input.field_name}
                    validateOn="change"
                    validators={
                      user_input.type !== 'email'
                        ? {
                            required,
                            minLength: minLength(2),
                            maxLength: maxLength(30)
                          }
                        : user_input.field_name !== 'email1'
                          ? {
                              validEmail,
                              confirmEmail,
                              maxLength: maxLength(50)
                            }
                          : {
                              validEmail,
                              maxLength: maxLength(50)
                            }
                    }
                    style={
                      user[user_input.field_name].touched
                        ? !user[user_input.field_name].valid
                          ? {
                              border: '4px solid red'
                            }
                          : null
                        : null
                    }
                    onClick={activeInput(user_input.field_name)}
                  />
                  <Errors
                    model={`.${user_input.field_name}`}
                    show="touched"
                    className="text-danger"
                    messages={{
                      required: intl.formatMessage({id: "forms.validations.default.required"}),
                      minLength: intl.formatMessage({id: "forms.validations.default.min"}),
                      maxLength: intl.formatMessage({id: "forms.validations.default.max"}),
                      confirmEmail: user_input.validation_texts.confirmEmail,
                      validEmail: user_input.validation_texts.validEmail
                    }}
                  />
                </div>
              </div>
            ))}
            <div className="col-sm-12" style={{ marginLeft: '-13px'}}>
              <div className="row">
                {this.props.site_info.show_policy_checkbox ? (
                  <div className="col-md-1" style={{ marginTop: '35px' }}>
                    <Control.checkbox
                      model={'.checkbox'}
                      type="checkbox"
                      name="checkbox"
                      id="cbtest"
                      validateOn="change"
                      validators={{
                        isChecked: () =>
                          this.props.user_data.user.checkbox === true
                      }}
                    />
                    <label
                      id="checkbox-label"
                      htmlFor="cbtest"
                    />
                    {this.props.user_data.forms.user.checkbox.touched
                      ? !this.props.user_data.user.checkbox
                        ? <div className={styles['floating-checkbox-label']} style={{marginTop: '-38.5px'}}></div>
                        : null
                      : null}
                  </div>
                ) : null}
                <div
                  className="col-md-11"
                  style={{
                    marginLeft: '-20px',
                    marginTop: '37px',
                    color: this.props.site_info.content_text
                  }}>
                  <p
                    style={{
                      marginLeft: '5px',
                      marginTop: '8px',
                      color: this.props.site_info.content_text
                    }}>
                    {intl.formatMessage({id: "dataforms.policy"})}
                  </p>
                </div>
              </div>
              <div style={{marginTop: '10px'}}>
                <Errors
                  model={'.checkbox'}
                  show="touched"
                  className="text-danger"
                  messages={{
                    isChecked: intl.formatMessage({id: "dataforms.confirm_policy"})
                  }}
                />
              </div>
              <div className="row justify-content-between">
                <div className="col-md-6" style={{ marginTop: '20px' }}>
                  {!this.props.keyboardState ? (
                    <button
                      type="submit"
                      className={`btn ${
                        this.props.site_info.drop_shadows ? 'drop-shadow' : null
                      }`}
                      style={{
                        marginTop: '10px',
                        fontSize: '1.7rem',
                        padding: '17px',
                        border: '1px solid #CBCBCBFF',
                        fontWeight: '600',
                        backgroundColor: this.props.site_info
                          .confirm_button_background,
                        color: this.props.site_info.confirm_button_text
                      }}>
                      {intl.formatMessage({id: "dataforms.book_appointment"})}
                    </button>
                  ) : null}
                </div>
                <div className="col-md-6" style={{ marginTop: '20px', textAlign: 'right', paddingRight: '0px',}}>
                  {!this.props.keyboardState ? (
                    <button
                      onClick={this.showPolicy.bind(this)}
                      className={`btn ${
                        this.props.site_info.drop_shadows ? 'drop-shadow' : null
                      }`}
                      style={{
                        marginTop: '10px',
                        fontSize: '1.7rem',
                        padding: '17px',
                        paddingRight: '-1000px',
                        border: '1px solid #FFCE00',
                        fontWeight: '600',
                        backgroundColor: '#FFCE00',
                        color: '#324E8B'
                      }}>
                      {intl.formatMessage({id: "dataforms.show_policy"})}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </Form>
        </div>
        <PrivacyPolicy />
      </div>
    );
  }
}

// No need to connect()!
function mapStateToProps(state) {
  return {
    user_data: state.user_data,
    session_info: state.session_info,
    site_info: state.site_info,
    selected_service: state.user_selected_service,
    selected_service_name: state.user_selected_service_name,
    selected_service_data: state.user_selected_service_data,
    selected_day: state.user_selected_day,
    selected_slot: state.user_selected_slot,
    selected_slot_time_of_slot: state.user_selected_slot_time_of_slot,
    selected_subtask: state.user_selected_subtask,
    user_person_count: state.user_count_persons,
    selected_language: state.user_selected_language,
    appointment_times: state.appointment_time,
  };
}

function languageChanger(lang) {
  let language;
  switch (lang) {
    case 'GB':
      language = 'en';
      break;
    case 'DK':
      language = 'da';
      break;
    case 'DE':
      language = 'de';
      break;
    default:
      language = 'en';
      break;
  }
  return language;
}

export default connect(mapStateToProps, actions)(UserForm);
